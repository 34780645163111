import './App.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Controller, EffectCoverflow, Autoplay } from 'swiper/modules';
import projects from './projects.json';
import { useParams } from 'react-router-dom';
export function Portfolio(props){
    const params = useParams();
    const project = projects[params.slug];
    return <div className="full bg-dark-4" style={{minHeight:"100vh", flex:1, height:'auto'}}>
        {project ?
        <div style={{alignItems:'center', justifyContent:'center', display:'flex', paddingTop:100, flexDirection:'column', minHeight:"100vh"}}>
            <h1 className='primary-text-color'>{project?.name}</h1>
            <Swiper
                effect="coverflow"
                grabCursor={true}
                centeredSlides={true}
                slidesPerView="auto"
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                modules={[EffectCoverflow, Autoplay, Controller, Pagination]}
                loop={true}
                id={"portfolio-frame"}
                pagination={{el:".swiper-pagination", clickable:true, enabled:true}}
            >
                {project?.images?.map(item=>(
                    <SwiperSlide className={`portfolio-swipes`} key={item?.key}>
                        <img src={item?.image} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-pagination"></div>
        </div>
        :null}
    </div>
}