//import logo from './logo.svg';
import './App.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Controller, Parallax, Autoplay } from 'swiper/modules';
// Import Swiper styles
import "swiper/css";
import "swiper/css/parallax";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/pagination";
import img1 from './assets/images/slider/namariq-design-hospital-building.jpeg';
import img2 from './assets/images/slider/namariq-design-changnan.jpg';
import img3 from './assets/images/slider/namariq-Interior-work.jpg';
import img4 from './assets/images/slider/RYD.jpg';
import architect from './assets/images/architect.png';
import about_image from './assets/images/work/about-us-image.jpg';
import { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight, KeyboardDoubleArrowDown, Pause, PlayArrow, ArrowForward, Label } from '@mui/icons-material';
import { gsap, ScrollToPlugin } from 'gsap/all';
import { Button, ButtonOutline } from './components/button';
import projects from './projects.json';
import services from './services.json';
import { Link } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { TextInput } from './components/TextInput';
import { SelectField } from './components/select-field';
import { contact_us, submit_career } from './client';
const featured_projects = Object.values(projects).slice(0,5);
gsap.registerPlugin(ScrollToPlugin);
const images=[img1, img2,img3, img4];
const texts = [
  {'text':"Healthcare facilities that inspire hope", key:'/portfolio/ksmc'},
  {'text':"Experiences through design excellence", key:'/portfolio/changan-showroom'},
  {'text':"Crafting spaces that inspires comfort and joy", key:'/portfolio/el-baher-albaher-hotel'},
  {'text':"Shaping Urban cities and communities", key:'/portfolio/urban-planning'}
]//Array.from(images);
const defaultContact = {name:'', email:'', message:''};
function Home() {
  const [swiper, setSwiper] = useState(null);
  const [swipertext, setSwiperText] = useState(null);
  const [index, setIndex] = useState(0);
  const [paused, setPaused] = useState(false)
  const speed=3000;
  const [contact, setContact] = useState(Object.assign({},{name:'', email:'', message:''}));
  const compileIndex=(swipe,dir)=>{

    if(parseInt(index)===0 && dir==="prev"){
      setIndex(images.length-1);
    }
    if(parseInt(index)===0 && dir==="next"){
      setIndex(index+1);
    }
    if((index>=0 && dir==="next") || dir===undefined){
      setIndex((index+1)>=images.length ?0:index+1);
    }
    if(index>=0 && dir==="prev"){
      setIndex((index-1)<0 ?images.length-1:index-1);
    }
    
  }
  useEffect(()=>{
    
  },[])
  return (
    <div id="app">
      <div className='banner-section'>
        <div className='floating-lines top'/>
        <div className='floating-lines right'/>
        <div className='slider-footer'>
          <div className='slider-controls'>
            <div className='slider-indicator'>
              <div className='indicator'></div>
              <div className='play-pause' onClick={()=>{
                  if(swiper?.autoplay?.running){
                    swiper?.autoplay?.stop();
                  }else{
                    swiper?.autoplay?.start();
                  }
                  setPaused(!paused)
                }}>
                {!paused || swiper?.autoplay?.running ? <Pause fontSize='medium'/> : <PlayArrow fontSize='medium'/> }
              </div>
            </div>
            <div className='slider-navigation'>
              <div className='arrows'>
                <ChevronLeft id="slider-nav-prev" color='white'  htmlColor='white' fontSize='medium' />
                <ChevronRight id="slider-nav-next" color='white' htmlColor='white'  fontSize='medium' />
              </div>
            </div>
          </div>
          <div className='slider-scroll-down' onClick={()=>gsap.to(window, {scrollTo:"#about-section", duration:2})}>
            <div className='text'>
              START EXPLORING
            </div>
            <div className='arrow-down'>
              <KeyboardDoubleArrowDown fontSize='medium' />
            </div>
          </div>
        </div>
        <div className='image-slider-section'>
          <div className='architect-in-slides'>
            <img src={architect} alt='architectural diagram for banner' />
            <div className='slider-numbering'>
              <div>{`${index+1}`.padStart(2,0)}</div>
            </div>
          </div>
          <div id="slider-frame">
            <Swiper
              id='swipe-banner'
              autoplay={{delay:2500}}
              loop={true}
              parallax={true}
              modules={[Parallax, Autoplay, Controller]}
              slidesPerView={1}
              speed={speed}
              controller={{control:swipertext}}
              onSwiper={setSwiper}
              effect='slide'
              onSlideNextTransitionEnd={(swipe)=>compileIndex(swipe,'next')}
              onSlidePrevTransitionEnd={(swipe)=>compileIndex(swipe,'prev')}
            >
              {images.map((item, idx)=>(
                <SwiperSlide className={`swipes`} key={idx}>
                  
                        <div
                          className='image'
                          style={{
                            backgroundImage: `url(${item})`,
                            height: "100vh",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: "100%",
                            position:'relative'
                          }}
                          data-swiper-parallax="20%"
                        >
                        </div>
                    <div

                      data-swiper-parallax="20%"
                      style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#000",
                          opacity: "0.4",
                          zIndex: 30,
                          
                    }}></div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
          <div id="slider-text-frame">
            <Swiper
              id='swipe-text-banner'
              loop={true}
              autoplay={false}
              modules={[Controller, Navigation, Pagination]}
              slidesPerView={1}
              controller={{control:swiper}}
              onSwiper={setSwiperText}
              navigation={{
                nextEl:'#slider-nav-next',
                prevEl:'#slider-nav-prev'
              }}
            >
              {texts.map((item, idx)=>(
                <SwiperSlide className={`swipes`} key={idx}>
                  <div className='slide-text-content'>
                    <div style={{width:'40%', display:'flex', textWrap:'wrap', flexWrap:'wrap'}}>
                        <div className='small-line'/>
                        <h3 style={{color:'white'}}>{item?.text}</h3>
                        <ButtonOutline asLink yellow href={item?.key}>View project</ButtonOutline>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
      </div>
      <div id="about-section" className='bg-white'>
        <div className='backshade bg-gray'></div>
        <div id="about-namariq" className='absolute'>
          <div className='container'>
            <h2>
              <span>About</span> Namariq
            </h2>
            <div className='line full'/>
            <article>
              <img src={about_image} />
              <div className='text'>
                <h2>Trusted & Reliable <br/> 
                <strong className='primary-text-color'>Architectural</strong> Firm</h2>
                <p>
                  NAMARIQ Engineering Consultants is a Class A company which specializes in engineering sectors,
                  providing solutions and services related to structural design, construction management and supervision
                  of infrastructure projects. NAMARIQ was founded in 1992 to provide services in Jeddah and Al-Baha
                  regions. Since becoming established, the company has not only gained a substantial number of
                  experienced professionals, but has flourished in the fields of residential, commercial, high-rise complex
                  and government infrastructure. NAMARIQ has offices in a new corporate head office tower in Riyadh,
                  aiming to reach a greater number of clients in the Riyadh region.
                </p>
                <p>
                  NAMARIQ has been fully certified according to the ISO 9001 for Quality Management System standard,
                  with certification maintained by the company’s accredited third-party partner SGS. NAMARIQ is also
                  awarded with the Bronze Category by the Ministry of Human Resources and Social Development for
                  creating a work environment suitable for people with disabilities.
                </p>
                <p>
                  We strive to be a world-class service provider in the field of engineering consultancy by utilizing
                  engineering solutions and methodologies that encompass all project management aspects. Nonetheless,
                  exceeding customers’ expectations and maintaining their loyalty and trust is our cardinal goal.
                </p>
                <div
                  className='gray-box'
                >
                  <div className='title'><h5>What we do :</h5></div>
                  <div style={{marginLeft:20, marginRight:20, width:1, height:20, background:"rgb(169,184,195)"}}/>
                  <div className='specialty'>
                    <span style={{fontSize:11, marginRight:20}}>Architecture</span>
                    <span style={{fontSize:11, marginRight:20}}>Design</span>
                    <span style={{fontSize:11, marginRight:20}}>Build</span>
                    <span style={{fontSize:11, marginRight:20}}>Supervision</span>
                    <span style={{fontSize:11, marginRight:20}}>Consultancy</span>
                  </div>
                </div>
                <Button onClick={()=>gsap.to(window, {scrollTo:"#project-section", duration:2})} style={{marginTop:30}}>Our works</Button>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div id="project-section" className='bg-dark-2'>
        <article>
          <div>
            <div className='container'>
              <div className='small-line'/>
              <h2 style={{color:'white'}}>Featured <span>Projects</span></h2>
              <div className='subtitle-section'>
                <div style={{width:'50%', fontSize:10, color:'#ccc', textTransform:'uppercase'}}>
                  <span>EXPLORING where creativity meets construction, to design, build and supervise a better structure and environment.</span>
                </div>
                <div><Button asLink href={"/portfolios"}>View more</Button></div>
              </div>
            </div>
            <div className='container-90'>
              <div className='slider-section container' style={{maxWidth:window.innerWidth*0.82}}>
                <Swiper
                  loop={true}
                  autoplay={false}
                  modules={[Controller, Navigation, Pagination, Parallax]}
                  slidesPerView={window.innerWidth<650?1:2}
                  //window.innerWidth<650?1:(window.innerWidth<1025?2:3)
                  spaceBetween={5}
                  parallax={true}
                  centeredSlides={true}
                  pagination={{el:'.swiper-pagination', enabled:true, clickable:true}}
                >
                  {featured_projects.map(item=>(
                    <SwiperSlide className='project-slides' key={item?.key}>
                      <div className='project-categories'>
                        {item?.categories?.map(category=>(
                          <span key={category.id}>{category?.name}</span>
                        ))}
                      </div>
                      <div>
                        <img className="bg-image" data-swiper-parallax="15%" src={item.featured_image} alt={item?.name}/>
                        <Link to={`/portfolio/${item?.key}`} className='project-names bg-dark-4 a'>
                          <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <div className='small-line'/>
                            <span className='project-names-text'>{item?.name}</span>
                          </div>
                          <div className='arrow'>
                            <ArrowForward htmlColor='#F9BF26' fontSize='medium'/>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className='swiper-pagination'></div>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div id='services-section' className='full bg-gray'>
        <div className='container'>
          <h2>Services <span>Provided</span></h2>
          <div className='line full'/>
          <div id='services'>
            <img src={architect}/>
            <div className='article'>
              We offer several ranges of services, from design, building, construction, technical engineering and consultancy;
              Below we highlight our core services:
              <ol>
                {services.map((item, idx)=>(
                  <li key={idx}>{item?.title}</li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div id="contact-career" className='full bg-dark-2'>
        <div id='contact'>
          <h2>Contact us</h2>
          <div style={{flexDirection: 'column', columnGap:10, display:'flex'}}>
            <div className='text-field-frame'>
              <TextInput required onChange={e=>setContact({...contact,"name":e.target.value})} id="outlined-basic" value={contact.name} label="Name" variant="outlined" />
            </div>
            <div className='text-field-frame'>
              <TextInput required onChange={e=>setContact({...contact,"email":e.target.value})} id="outlined-basic" value={contact.email} label="Email" variant="outlined" />
            </div>
            <div className='text-field-frame'>
              <TextInput required value={contact.message} onChange={(e)=>{setContact({...contact,"message":e.target.value})}} rows={5} id="outlined-basic" label="Message" variant="outlined" multiline />
            </div>
            <Button disabled={contact?.name?.length < 2 && contact?.email?.length < 6 && contact?.message < 10} onClick={()=>{
              contact_us(contact)
              .then(r=>{
                alert(r?.data?.message);
                setContact(Object.assign({},defaultContact));
              })
              .catch(e=>{
                alert(e?.response?.data?.message ? e?.response?.data?.message : "Sorry, an error occured, but you can reach us directly at info@namariq.net.")
              })
            }}>Submit</Button>
          </div>
        </div>
        <div className='vertical-line'/>
        <div id='career'>
          <h2>Career with us</h2>
          <form onSubmit={submit_career} style={{flexDirection: 'column', columnGap:10, display:'flex'}}>
            <div className='text-field-frame'>
              <TextInput required name="name" label="Name" variant="outlined" />
            </div>
            <div className='text-field-frame'>
              <TextInput type='email' required name="email" label="Email" variant="outlined" />
            </div>
            <div className='text-field-frame'>
              <TextInput required name="dob" label="Date of Birth" type='date' variant="outlined" InputLabelProps={{ shrink: true }} />
            </div>
            <div className='text-field-frame'>
              <TextInput required name="nationality" label="Nationality" variant="outlined" />
            </div>
            <div className='text-field-frame'>
              <SelectField
                labelId="select-discipline-label"
                id="select-discipline"
                defaultValue={0}
                label="Discipline"
                name="discipline"
                placeholder="Discipline"
                className='select'
              >
                <MenuItem value={0} disabled>Discipline</MenuItem>
                <MenuItem value={"B.S Architecture"}>B.S Architecture</MenuItem>
                <MenuItem value={"B.S Civil Engineering"}>B.S Civil Engineering</MenuItem>
                <MenuItem value={"B.S Mechanical Engineering"}>B.S Mechanical Engineering</MenuItem>
                <MenuItem value={"B.S Electrical Engineering"}>B.S Electrical Engineering</MenuItem>
              </SelectField>
            </div>
            <div className='text-field-frame'>
              <SelectField
                labelId="select-experience-label"
                id="select-experience"
                label="Experience"
                name="experience"
                defaultValue={0}
                className='select'
              >
                <MenuItem value={0} disabled>Years of Experience</MenuItem>
                <MenuItem value={"0-5 years"}>0-5 years</MenuItem>
                <MenuItem value={"5-10 years"}>5-10 years</MenuItem>
                <MenuItem value={"10-15 years"}>10-15 years</MenuItem>
                <MenuItem value={"15-30 years"}>15-30 years</MenuItem>
              </SelectField>
            </div>
            <div className='text-field-frame'>
              <TextInput required type='file' name='fileToUpload' />
            </div>
            <div className='text-field-frame'>
              <TextInput required name={"description"} rows={5} label="Briefly describe past two projects" variant="outlined" multiline />
            </div>
            <Button type={"submit"}>Submit</Button>
          </form>
        </div>
      </div>
      <div className='fixed-footer'>
        <div className='fixed-footer-bar'></div>
      </div>
    </div>
  );
}

export default Home;

// <div
// style={{
//   backgroundImage: `url(${img1})`,
//   height: "100vh",
//   backgroundAttachment: "fixed",
//   backgroundSize: "cover",
//   backgroundPosition: "center",
//   transform:"translateX(-300)"
// }}
// data-swiper-parallax-x="50%"
// />
//{/* <SwiperSlide><img src={img2} data-swiper-parallax-x="30%"/></SwiperSlide> */}